import React from 'react';
// import { hot } from 'react-hot-loader/root';
import Layout from './Components/Layout.js';
import './index.css';

const App = () => {
  return(
    <div>
      <Layout />
    </div>
  )
};

// export default hot(App);
export default App;